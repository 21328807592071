export const lessonsLevels = [
  {
    title: 'no level',
    values: [0],
    queryParam: null,
  },
  {
    title: 'beginner',
    values: [1, 2, 3],
    queryParam: '1..3',
  },
  {
    title: 'intermediate',
    values: [4, 5, 6],
    queryParam: '4..6',
  },
  {
    title: 'advanced',
    values: [7, 8, 9],
    queryParam: '7..9',
  },
];

export const lessonsLevelsForPages = lessonsLevels.filter(level => !level.values.includes(0));

export function levelToObject(level) {
  return lessonsLevels.find(item => item.values.includes(level)) || lessonsLevels[0];
}

export function getLevelValues(title) {
  const level = lessonsLevels.find(item => item.title === title);
  return level && level.values || [0];
}
