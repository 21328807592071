import React from "react";
import PropTypes from 'prop-types';
import {StaticQuery} from 'gatsby';
import { graphql } from 'gatsby';
import elasticlunr, { Index } from "elasticlunr";
import {levelToObject} from "../../../helpers/level-transformer.helper";
import moment from "moment";
import './admin-lessons-search.scss'
import { dateFormat } from '../../../constants/date-format.const';

const AdminLessonSearch = ({ query }) => (
  <StaticQuery
    query={graphql`
      query AdminLessonSearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => {
      elasticlunr.clearStopWords();
      const adminLessonURL = '/admin/#/collections/lessons/entries/';
      const index = Index.load(data.siteSearchIndex.index); // Create an elastic lunr index and hydrate with graphql query results
      const adminSearchResults = index.search(query, { expand: true }).map(({ ref }) => index.documentStore.getDoc(ref));

      if(adminSearchResults.length > 0 && query) {
        return (
          <React.Fragment>
            <section className="container">
              <div className="row">
                <div className="col col-12">
                  <div className="mt-5 admin-search-go-back">
                    <a href='/admin/#/'>Go back to CMS dashboard</a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-12">
                  <h2>Search results for "{query}":</h2>
                </div>
              </div>
              <div className="row">
                <div className="col col-12">
                  <ul className="list-unstyled mt-3">
                    {adminSearchResults.map(({node}) => (
                      <li key={node.fields.lessonPath} className="mb-3 pb-3 border-divider">
                        <a href={adminLessonURL + node.frontmatter.id} className="link-primary lesson d-flex align-items-start flex-wrap flex-sm-nowrap">
                          <span className="d-flex flex-column flex-shrink-2 flex-grow-1 text-wrap">
                            <span className="lesson-title my-2 my-sm-2">{node.frontmatter.title}</span>
                            <span className="d-flex flex-wrap mb-2 mb-sm-1 admin-search-desc-color">
                              { node.frontmatter.artist && <small className="details text-capitalize">{node.frontmatter.artist} /</small> }
                              { node.frontmatter.genre && <small className="details text-capitalize">{node.frontmatter.genre} /</small> }
                              { node.frontmatter.instrument && <small className="details text-capitalize">{node.frontmatter.instrument} /</small> }
                              { node.frontmatter.level > 0 && <small className="details text-capitalize">{levelToObject(node.frontmatter.level).title}</small> }
                              { node.frontmatter.createdAt && <small className="details">{ moment(node.frontmatter.createdAt).format(dateFormat) }</small> }
                            </span>
                          </span>
                        </a>
                      </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col col-12 mt-5 admin-search-go-back">
                  <a href='/admin/#/'>Go back to CMS dashboard</a>
                </div>
              </div>

            </section>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <section className="container">
              <div className="row">
                <div className="col col-12">
                {query && (<p className="mt-5">{`We\'re sorry, but there are no lessons that match "${query}".`}</p>)}
                </div>
              </div>
              <div className="row">
                <div className="col col-12">
                  <a href='/admin/#/'>Go back to CMS dashboard</a>
                </div>
              </div>
            </section>
          </React.Fragment>
        )
      }
    }}
  />
);


AdminLessonSearch.propTypes = {
  query: PropTypes.string,
};

export default AdminLessonSearch;
