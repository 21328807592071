import React from "react";
import AdminLessonSearch from "../components/Lessons/AdminLessonSearch/AdminLessonSearch";

export default class AdminLessonsSearchPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const query = urlParams.get('query') ?
      urlParams.get('query') :
      typeof window !== 'undefined' ?
      localStorage.getItem("cms_search_query") :
      null;

    return (
      <AdminLessonSearch query={query}/>
    )
  }
}
